import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bf5dbd5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "text-popup" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.showPopup)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.popupTitle,
        onOnClickOutsidePopup: _ctx.hideEditPopup,
        onOnHide: _ctx.hideEditPopup
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  class: "input-label",
                  innerHTML: 
              `<span style='color: #D64D10'>${_ctx.$t(
                'common.requiredText'
              )}</span>` + _ctx.$t('group.editGroupName')
            
                }, null, 8, _hoisted_3),
                _createVNode(_component_validation_text_field, {
                  "keep-error-space": false,
                  name: "groupName",
                  rules: "required",
                  message: {
              error_required: _ctx.$t('errors.required'),
            },
                  value: _ctx.newName,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newName) = $event))
                }, null, 8, ["message", "value"])
              ]),
              _createVNode(_component_FlatButton, {
                text: _ctx.$t('group.editGroupButton'),
                class: "confirm-button",
                enabled: _ctx.newName != '',
                "on-click": _ctx.submitBtn
              }, null, 8, ["text", "enabled", "on-click"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}