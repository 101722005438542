
import { defineComponent,reactive, toRefs, watch } from "vue";
import Popup from "@/components/popups/Popup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { GroupRepository, RepositoryFactory } from "@/lib/https";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";


export default defineComponent({
  name: "UpdateGroupDescriptionPopup",
  components: {
    FlatButton,
    Popup,
    ValidationTextField,
  },
  props: {
    id: { type: String, required: true },
    name: {type: String, required: true},
    showPopup: { type: Boolean, default: false },
    popupTitle: { type: String, required: true },
    oldDescription: { type: String, default: ""}
  },
  emits: ["hideEditPopup", "onChangeSuccess"],
  setup(props, {emit}) {
    const state = reactive({
        newDescription: ""
    });
    const store = useStore();
    const { t } = useI18n();

    watch(
        () => props.showPopup,
        (val) => {
            if(val) {
                state.newDescription = props.oldDescription || "";
            }
        }, {immediate: true}
    );
     const showSuccessNotification = (text: string) =>
      store.commit("notification/showSuccessNotification", text);
    const showErrorNotification = (text: string) =>
      store.commit("notification/showErrorNotification", text);


    const {updateGroup} = RepositoryFactory.getRepository<GroupRepository>(GroupRepository);
    const submitBtn =  async () => {
        const data = { 
            description: state.newDescription,
            id: props.id,
            name: props.name
        };
        await updateGroup(data).then((response: any) => {
            if(response) {
                showSuccessNotification(t("notification.updateSuccess"))
                emit("onChangeSuccess", state.newDescription)
            }
        }).catch((err: any) => {
            if(err.data)
            showErrorNotification(err.data.message)
        }).finally(() => {emit("hideEditPopup")});
    }


    return { ...toRefs(state), submitBtn};
  },
  methods: {
    hideEditPopup() {
      this.$emit("hideEditPopup");
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
