
// @ is an alias to /sr
import {
  defineComponent,
  reactive,
  toRefs,
  inject,
  onMounted,
  watch,
  ref,
} from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import UpdateGroupNamePopup from "@/components/popups/groups/UpdateGroupNamePopup.vue";
import UpdateGroupDescriptionPopup from "@/components/popups/groups/UpdateGroupDescriptionPopup.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import defaultAvatar from "@/assets/icons/default-avatar.svg";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";

import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { GroupRepository, RepositoryFactory } from "@/lib/https";

export default defineComponent({
  name: "GroupDetails",
  components: {
    TextButton,
    BreadScrum,
    AvatarCircle,   
    AfterLoginPageLayout,
    UpdateGroupNamePopup,
    UpdateGroupDescriptionPopup
  },
   props: {
    groupID: { type: String, required: true },
    groupName: { type: String, required: true },
  },
  setup(props) {
    const state = reactive({
      breadscrumLinks: [] as any,
      groupInfo: {
          name: "",
          description: ""
      } as any,
    });
    const popupState = reactive({
        editGroupName: false,
        editGroupDescription: false,
    });

    const groupMembers = ref([] as any);

    watch(
      () => state.groupInfo.name,
      (val) => {
        if(val) state.breadscrumLinks[0].text = val;
      }
    )

    const isMobile = inject("isMobile");

    const store = useStore();

    const { t } = useI18n();

    const showSuccessNotification = (text: string) =>
      store.commit("notification/showSuccessNotification", text);
    const showErrorNotification = (text: string) =>
      store.commit("notification/showErrorNotification", text);

    const {
        getDetailGroup,
        getMemberGroup,
        deleteMember
    } = RepositoryFactory.getRepository<GroupRepository>(GroupRepository);

    const removeMember = async (user: any, index: number) => {
      const response = await deleteMember(props.groupID, user.id).catch((e) => {
        // alert(e.data.message);
        showErrorNotification(e.data.message);
        return;
      });
      if (response) {
        (groupMembers.value as any).data.splice(index, 1);
        showSuccessNotification(t("notification.deleteSuccess"));

      }
    };

 
    onMounted(async () => {
        state.breadscrumLinks = [
            { text: props.groupName, to: "/group" },
            {
            text: t("group.groupDetails"),
            to: `?groupID=${props.groupID}&groupName=${props.groupName}`,
            },
        ];
        state.groupInfo = await getDetailGroup(props.groupID);
        groupMembers.value = await getMemberGroup(props.groupID,1,100);
    });


    return {
      ...toRefs(state),
      ...toRefs(popupState),
      groupMembers,
      isMobile,
      defaultAvatar,
      getFilePathFromUrl,
      removeMember
    };
  },
});

