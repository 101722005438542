import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f40c7478"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "text-popup" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_popup = _resolveComponent("popup")!

  return (_ctx.showPopup)
    ? (_openBlock(), _createBlock(_component_popup, {
        key: 0,
        title: _ctx.popupTitle,
        onOnClickOutsidePopup: _ctx.hideEditPopup,
        onOnHide: _ctx.hideEditPopup
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  class: "input-label",
                  innerHTML: 
              _ctx.$t('group.editGroupDescription')
            
                }, null, 8, _hoisted_3),
                _createVNode(_component_ValidationTextField, {
                  "is-multiline": true,
                  placeholder: '',
                  "keep-error-space": false,
                  class: "multiline",
                  name: "description",
                  value: _ctx.newDescription,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newDescription) = $event)),
                  "max-length": 200
                }, null, 8, ["value"])
              ]),
              _createVNode(_component_FlatButton, {
                text: _ctx.$t('group.editGroupButton'),
                class: "confirm-button",
                "on-click": _ctx.submitBtn
              }, null, 8, ["text", "on-click"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "onOnClickOutsidePopup", "onOnHide"]))
    : _createCommentVNode("", true)
}